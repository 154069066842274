#add-course {
    display: flex;
    flex-wrap: wrap;
    background: whitesmoke;
    padding: 20px;
    border-radius: 5px;
    justify-content: center;
}

.add-course-title {
    font-size: 3rem;
    width: 100%;
    border-bottom: dashed 1px var(--secondary);
}

.add-course-form__wrapper {
    width: 100%;
}

.add-course-left_col {
    width: 50%;
}

.add-job-long-desc {
    margin-top: 20px;
}