#wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.uploadForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}