.avatar-wrapper {
  margin: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid var(--primary);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 15;
}

.avatar-wrapper img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
