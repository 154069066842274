.upload-gpimg-btn {
    font-size: 2rem;
    font-weight: 400;
}

input[type="file"] {
    display: none;
}

.gen-image-preview {
    max-height: 200px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid var(--primary);
}

.file-selection__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}