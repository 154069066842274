.sub-menu__wrapper {
    position: fixed;
    left: 8rem;
    right: 0;
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    height: 4.5rem;
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 60%);
    overflow: hidden;
    z-index: 1000;
}

.sub-menu-list {
    list-style: none;
    display: flex;
    font-size: 1.5rem;
    
}

.sub-menu-item {
    padding: 3px 15px;
    color:white;
    border-right: 1px dotted var(--primary);
}

.sub-menu-item-btn {
    background-color: transparent;
    outline: none;
    border: none;
    color:white;
    font-size: 1.5rem;
    transition: .3s;
    cursor: pointer;
    display: block;
}

.sub-menu-item-btn:hover,
.sub-menu-item-btn:focus {
    outline: none;
    border: none;
    color: var(--primary);
}

