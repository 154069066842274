#lessonList{
    border-radius: 10px;
    margin: 20px;
   padding-bottom: 4rem;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    background-color: #ddd;
}

.lessonListItemWrappper {
    display: flex;
}

.lessonListToolbar {
    width: 100%;
    margin-bottom: 2rem;
    background-color: #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0px 0;
}

.lessonListToolbar h1 {
    margin: 0;
    margin-left: 2rem;
    font-size: 2.6rem;
    color: #333;
}



.lessonListWrappper {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width:2186px) {
    .lessonListWrappper {
        justify-content: center;
    }
}