
#content-video-upload__wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.content-video-upload-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}