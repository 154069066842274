.page-header__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 8rem;
    margin: 0;
    padding: 5px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    z-index: 4;
  }

  .page-title {
      margin: 0;
      font-weight: 200;
      font-size: 2.5rem;
      margin-left: 20px;
  }

  .tutor-welcome__wrapper {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .welcome-text {
      padding: 0;
      margin-right: 10px;
      font-size: 1.8rem;
      font-weight: 200;
  }

  .logout {
      background-color: transparent;
      display: block;
      border: none;
      color: white;
      padding: 10px;
      font-size: 2rem;
      transition: .3s;
      cursor: pointer;
  }

  .logout:hover {
      color: var(--secondary-500);
  }

  .logout:active, 
  .logout:focus {
    border: none;
    outline: none;
}
  