.add-course-main-description {
    margin-top: 20px;
    width: 100%;
    font-size: 1.8rem;
}

.course-form-editor-wrapper{
    border: 1px solid var(--input-border);
  }
  
  .editor{
    padding: 20px;
    background-color: white;
    min-height: 500px;
    max-height: 60vh;
    color: #555;
    overflow-x: scroll;
  }

  .add-course-form-action {
      display: flex;
      justify-content: flex-end;
  }

  .add-course-form-btn {
      font-size: 1.8rem;
  }

  @media screen and (max-height:750px) {
      .editor {
          max-height: 50vh;
          min-height: 5rem;
      }
  }