.userListItem{
    background-color: whitesmoke;
    margin: .2rem 0;
    padding: 2rem;
    cursor: pointer;
    transition: .3s;
    display: flex;
}

.userListItem:hover {
    background-color: lightgray;
    transform: scale(1.01);
}

.name {
    font-size: 1.6rem;
    color: #333;
    font-weight: 200;
    width: 25%;
    border-right: .1rem dotted var(--primary);
}

.email {
    padding-left: 1rem;
    font-size: 1.6rem;
    color:  var(--secondary);
    font-weight: 200;
    width: 25%;
    border-right: .1rem dotted var(--primary);
}

.courses {
    text-align: right;
    padding-left: 1rem;
    font-size: 1.6rem;
    color:  var(--secondary);
    font-weight: 200;
    width: 25%;
   
}