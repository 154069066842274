.backLink {
    margin: 0;
    margin-bottom: 2rem;
    color: white;
    font-weight: 600;
    font-size: 1.8rem;
    cursor: pointer;
}

.user {
   margin: 0;
}

.userHeader {
    margin: 1rem 0;
    padding: 2rem;
    background-color: whitesmoke;
}

.userHeader h1 {
    font-size: 3rem;
    margin-bottom: 0;
}

.userHeader h2 {
    margin-top: 0;
    padding-bottom: 2rem;
    color: #333;
    border-bottom: dotted .1rem grey;
}

.userDetails {
    margin: 1rem 0;
    margin-top: -1rem;
    padding: 2rem;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
}

.postsContainer {
    
}