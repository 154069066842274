#lesson {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  
}

.lessonCard {
    max-width: 900px;
}

.lessonTitle {
    width: 80%;
    font-size: 2rem;
    margin: 0px;
    padding: 10px;
}
.lessonNumber {
    margin: 5px 0;
    padding: 1rem 2rem;
    background-color: var(--primary);
    color: white;
    font-weight: 900;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

}
.lessonMediaWrapper {
    display: flex;
    width: 100%;
}
.lessonVideoContainer {
 min-width: 300px;
}

.videoNone {
  background-color: black;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.videoNoneContent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoNoneContentText {
    color: gray;
    font-size: 1.6rem;
    font-weight: 600;
}

.downloadableContainer {
    background-color: var(--primary);
    border-radius: 5px;
    margin: 0 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    flex-grow: 2;
}

.downloadableContainer h1 {
 padding: 20px;
 font-weight: 200;
 margin: 0;
}

.downloadableContentLink {
    padding: 20px;
    color: white;
    font-weight: 200;
}

.textTitle {
    margin-bottom: 5px;
}

.text {
    margin: 0 0 20px 0;
    border-radius: 5px;;
    width: 100%;
    color: #333;
    font-size: 1.6rem;
    background-color: white;
    padding: 0px 10px;
}

.duration {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.duration p {
 color: gray;
 font-size: 2rem;
 margin: 3px;
 margin-bottom: 20px;

}


.footer   {    
    display: flex;
    margin-top: 2rem;
    width: 100%;
    justify-content: space-between;
    color: grey;
    border-top: 1px dotted darkgrey;
    font-size: 1.2rem;
}

.footer h5 {
 margin: 5px 0;
}

.footer p {
    margin: 5px 0;
}

.actionsWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #ddd;
    border-radius: 5px;
    padding: 0 5px;
}

.actionBtn {
    margin: 10px 3px;
}

.warningActions {
    width: 100%;
    display: flex;
}

.warningButton {
    flex-grow: 2;
}
