.avatar-icon-wrapper {
  margin: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid var(--primary);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 15;
}

.avatar-icon-wrapper img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
