#course {
    display: flex;
    flex-wrap: wrap;
    background: whitesmoke;
    padding: 20px;
    border-radius: 5px;
    justify-content: space-around;
}

.course-details-title {
    font-size: 3rem;
    width: 100%;
    border-bottom: dashed 1px var(--secondary);
}

.wrapper-bottom {
    margin-bottom: 40px;
}

.course-feature-image-container {
    margin: 1rem;
    width: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.course-feature-image {
    width: 100%;
    max-width: 30rem;
}

.course-details-media__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    margin-bottom: 60px;
    background-color: white;
    border: 0.1rem #ccc solid;
    border-radius: .6rem;
    background-position: center;
    background-size: cover;
}

.course-edit-media-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.course-details-media__wrapper h2 {
    width: 100%;
    color: white;
    font-size: 1.7rem;
    margin: 0;
}

.course-description__wrapper {
    min-height: 340px;
    width: 50%;
    min-width: 340px;
    max-width: 800px;
    white-space:pre-line;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: gray;
    padding: 0 20px;
}

.course-description__wrapper p {
    margin: 0;
}

.course-video__wrapper {
    margin-left: 20px;
    min-height: 340px;
    min-width: 600px;
    max-height: 500px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.course-featured-image__wrapper {
    min-width: 600px;
}

.course-featured-image,
.course-featured-image-none {
    margin-left: 20px;
    min-height: 340px;
    min-width: 600px;
    max-height: 500px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.course-featured-video-none {
    min-height: 340px;
    min-width: 600px;
    max-height: 500px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: lightgray;
}

.course-featured-image{
    background: var(--primary);
}

.course-featured-image-none {
    background: lightgray;
}

.course-featured-image img {
    max-height: 340px;
    object-fit: contain;
}

.image-select-form {
    display: flex;
    flex-direction: column;

}

.upload-rules    {
    text-align: center;
    color: #333;
    font-size: 1.4rem;
    margin-top: -15px;
    margin-bottom: 10px;
}


.course-actions__wrapper {
    width: 100%;
    padding-top: 10px;
    border-top: 1px dashed grey;
    display: flex;
    justify-content: flex-end;
}

.course-dates__wrapper {
    width: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    color: var(--secondary);
}

.delete-warning-wrapper {
    max-width: 400px;
}

.warning-action__wrapper {
    display: flex;
    justify-content: stretch;
    margin-top: 20px;
}

.delete-warning-message {
    max-width: 350px;
}

.warning-button {
    flex-grow: 1;
    margin: 5px;
}

.update-course-modal__wrapper {
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.course-description-edit-btn {
    margin: 20px ;
}

.course-edit-desc-action__wrapper {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #ddd;
    border-radius: 5px;
}

.update-desc-course-modal__wrapper {
    width: 90vw;
    max-width: 1200px;
}

.course-price__wrapper {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    min-width: 300px;
    padding: 0;
    align-items: center;

}
.course-price-title{
    margin: 0;
    color: #333;
    font-size: 2rem;
    padding: 5px 30px;
   
}

.course-price__content{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
}

.course-price-price{
   font-size: 3.5rem;
}

.price-save-modal-btn,
.price-edit-modal-btn {
    width: 100%;
    margin: 3px;
}

.price-edit-modal-btn {
    margin-bottom: 20px;
}

.course-right-col {
    margin: 0;
    padding: 0;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.featureActions {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media only screen and (max-width:1086px) {

    .course-description__wrapper {
        width: 100%;
    }

}