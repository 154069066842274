main {
  width: 100%;
  display: flex;
  padding: 0 ;
}

.page__wrapper {
  margin-left: 80px;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 2;
}

.page-content {
  margin-top: 5rem;
  padding: 0 20px;
  flex-grow: 2;
}

.spinner-margin-top {
  margin-top:100px;
}