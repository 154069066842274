.wrapper {
    padding: 0 20px;
  }
  
  .description {
      margin-top: 2rem;
      width: 100%;
  }
  
  .editorWrapper{
      border: 1px solid var(--input-border);
    }
    
    .editor-assignment{
      padding:2rem;
      background-color: white;
      min-height: 500px;
      color: #555;
    }
  
    .formAction {
        display: flex;
        justify-content: flex-end;
    }
  
    .btn {
        font-size: 1.8rem;
    }
  
    .numberInput {
      justify-content: flex-start;
        width:200px;
    }

    .durationInput {
      width:260px;
    }
  
    .add-course-media__wrapper {
      padding: 2rem;
      
    }
  
    .left{
      display: flex;
      flex-wrap: wrap;
    }
  
    .inputLeftMargin {
      margin-left: .5rem;
    }
  
    .question-input {
      flex-grow: 1;
    }
  
    .assignment-choose-q-btn {
      margin: 0  0 1rem .5rem;
      padding: 0 2rem;
    }
  
    .question-value-container {
      flex-grow: 1;
      margin-left: 5px;
      margin-bottom: 1rem;
      padding: .8rem 1.6rem;
      line-height: 2.5rem;
      font-size: 1.4rem;
      font-weight: 500;
      border-radius: .6rem;
      background-color: var(--input-background);
    }