.wrapper {
    margin: .1rem 0;
    padding: .2rem;
    font-size: 1.4rem;
}

.content {
    background-color: white;
    padding: 1rem;
    margin: 0;
    color: #333;
    font-weight: 600;
}

.content span {
    margin-right: 1rem;
    color: var(--primary);
    font-weight: 200;
}