.wrapper {
    margin: 0;
    padding: 1rem;
    
    min-width:35rem;
    border-radius: 1rem;
    border: .0rem solid var(--primary);
   }
   
   .wrapper h1 {
       text-align: center;
       color: var(--secondary);
       font-weight: 300;
   
   }