.address {
 margin: 0;
 padding: 1rem;
 width: 25%;
 min-width:35rem;
 border-radius: 1rem;
 border: .0rem solid var(--primary);
}

.address h1 {
    text-align: center;
    color: var(--secondary);
    font-weight: 300;

}