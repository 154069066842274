#course-list {
    display: flex;
    flex-wrap: wrap;

}

.course-list-item__wrapper {
    margin: 3px;
    padding:0;
    width: 100%;
    display: flex;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    transition: .2s;
}

.course-list-item__wrapper:hover {
    background-color: whitesmoke;
}

.course-list-item__header {
    margin: 0;
    padding: 0;
    width: 300px;
    display: flex;
    align-items: center;
    background-color: #333;
    color: white;
}


.course-list-item__title {
    padding: 5px;
    text-align: center;
    flex-grow: 2;
   
}

.course-list-item__body {
   
    display: flex;
    justify-content: center;
}

.course-list-item__item {
 display: flex;
 justify-content: space-around;
 align-items: center;
 padding: 10px;
}

.course-list-item__item-label {
    color: var(--secondary);
    font-size: 1.6rem;
    font-weight: 300;
margin:  10px;
}
.course-list-item__item-data {
    color: var(--primary);
    font-size: 2rem;
    margin: 0;
}