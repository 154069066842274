@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');

:root {
  --primary-100: #5DCFE3;
  --primary: #27A1B7;
  --primary-500: #08778B;

  --secondary-100: #EEEEEE;
  --secondary: #8B8B8B;
  --secondary-500: #434343;

  --input-color: #99A3BA;
  --input-border: #CDD9ED;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #27A1B7;

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background:#f3f3f3;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
  background: #08778B;
  background-image: url("./img/White-bg.png");
  background-repeat: repeat;
  background-size: 350px;
  background-attachment: fixed;
}

body {
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  color: #27A1B7;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 9rem;
  margin-bottom: 5.6rem;
  width: 100%;
  max-width: 1750px;
  min-height: 500px;
}

a {
  color: var(--primary);
  text-decoration: none;
}

a:hover {
  color: var(--primary-500);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#root {
  width: 100%;
  display: flex;
}

.unread {
  background-color: darkred;
  padding: 5px;
  color: white;
  font-weight: 900;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid whitesmoke;
  font-size: 1.1rem;
}
